import { animate, scroll } from 'motion';

export default () => {
  const droplets = document.querySelector('[data-el="droplet-container"]');

  const viewportHeight = window.innerHeight;
  const scrollHeight = document.querySelector('body').scrollHeight;
  const scale = scrollHeight / viewportHeight;
  const speed = -(scale * 500);

  scroll(animate(droplets, { y: [0, speed] }));
};
