import { animate, inView, stagger } from 'motion';

export default function homepage() {
  const productSlideIn = document.querySelectorAll('[data-el="product-image"]');

  productSlideIn.forEach((el) => {
    el.style.opacity = 0;
  });
  inView(
    productSlideIn,
    ({ target }) => {
      animate(target, { opacity: [0, 1], y: [20, 0] }, { duration: 0.8 });
    },
    { amount: 0.5 }
  );
}
