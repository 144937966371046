import { animate, scroll, spring, timeline } from 'motion';

export default function HomepageHero() {
  const heroTitle = document.querySelector('[data-el="hero-lockup"]');
  const heroBG = document.querySelector('[data-el="hero-bg"]');
  const heroBloke = document.querySelectorAll('[data-el="hero-bloke"]');
  const heroBiodiversity = document.querySelector(
    '[data-el="hero-biodiversity"]'
  );
  const heroTagline = document.querySelector('[data-el="hero-tagline"]');
  const heroCTA = document.querySelector('[data-el="hero-cta"]');

  // Hero BG parallax
  scroll(animate(heroBG, { y: [0, 600] }));
  scroll(animate(heroBiodiversity, { y: [0, 300] }));
  scroll(animate(heroBloke, { y: [0, 300] }));

  const animateTitle = [
    heroTitle,
    { scale: [0, 1] },
    {
      duration: 0.25,
      easing: spring({ stiffness: 80, damping: 10 }),
    },
  ];
  const animateTitleOpacity = [
    heroTitle,
    { opacity: [0, 1] },
    {
      duration: 0.8,
      at: '<',
    },
  ];

  // Tagline animation
  const animateTagline = [
    heroTagline,
    { opacity: [0, 1] },
    { duration: 0.5, at: '0.5' },
  ];
  const animateTaglineOpacity = [
    heroTagline,
    { opacity: [0, 1] },
    {
      duration: 0.5,
      at: '<',
    },
  ];

  // CTA animation
  const animateCTA = [heroCTA, { opacity: [0, 1] }, { duration: 0.5 }];

  const sequence = [
    animateTitle,
    animateTitleOpacity,
    animateTagline,
    animateTaglineOpacity,
    animateCTA,
  ];

  timeline(sequence, {
    delay: 0.5,
  });
}
