import { animate, scroll, stagger, spring, timeline } from 'motion';
import SplitType from 'split-type';

const titleVerticalOffset = {
  small: 10,
  medium: 15,
  large: 20,
};

export default function HomepageHero() {
  const heroTitle = document.querySelector('[data-el="hero-title"]');
  const heroLineup = document.querySelectorAll(
    '[data-el="product-lineup"] div'
  );
  const heroBg = document.querySelector('[data-el="hero-bg"]');
  const heroBgMask = document.querySelector('[data-el="hero-bg-mask"]');
  const heroTagline = document.querySelector('[data-el="hero-tagline"]');

  // Hero BG parallax
  scroll(animate(heroBg, { y: [0, 700] }));
  scroll(animate(heroBgMask, { y: [0, 500] }));

  // Hero title animation
  const splitHero = new SplitType(heroTitle, {
    types: 'chars',
    charClass: 'overflow-hidden -my-16',
  });

  let verticalOffset = titleVerticalOffset.large;
  if (window.innerWidth < 640) {
    verticalOffset = titleVerticalOffset.small;
  } else if (window.innerWidth < 1024) {
    verticalOffset = titleVerticalOffset.medium;
  }

  const animateTitle = [
    splitHero.chars,
    { y: [verticalOffset, 0] },
    {
      duration: 1.6,
      delay: stagger(0.05),
      easing: spring({ stiffness: 40, damping: 4 }),
    },
  ];
  const animateTitleOpacity = [
    splitHero.chars,
    { opacity: [0, 1] },
    {
      duration: 0.8,
      delay: stagger(0.05),
      at: '<',
    },
  ];

  // Bottle animation
  const animateLineup = [
    heroLineup,
    { opacity: [0, 1], y: [verticalOffset, 0] },
    { duration: 0.7, at: '<', delay: stagger(0.25) },
  ];

  // Tagline animation
  const animateTagline = [
    heroTagline,
    { opacity: [0, 1] },
    { duration: 0.5, at: '0.5' },
  ];

  timeline([animateTitle, animateTitleOpacity, animateTagline, animateLineup], {
    delay: 0.5,
  });
  heroTitle.style.opacity = 1;
}
