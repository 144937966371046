export default function init() {
  // Ticker animation
  const ticker = document.querySelector('[data-el="ticker"] p');
  let scroll = window.scrollY / 1.5;
  const updateScroll = () => {
    scroll = window.scrollY / 1.5;
    ticker.style.transform = `translateX(-${scroll}px)`;
  };
  setInterval(updateScroll, 1);
}
