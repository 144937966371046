import Swiper from 'swiper';

export default function init() {
  const infoCardSlider = document.querySelector('[data-el="info-card-slider"]');
  const infoCardTabs = document.querySelectorAll(
    '[data-el="info-card-tabs"] button'
  );
  const variantButtons = document.querySelectorAll(
    '[data-el="product-variant-button"]'
  );

  infoCardTabs.forEach((element, i) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      const myIndex = element.dataset.tabNum;
      swiper.slideTo(myIndex);
    });
    if (i === 0) {
      element.classList.add('text-primary', 'underline');
    }
  });

  variantButtons.forEach((element, i) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      swiper.slideTo(0);
    });
  });

  const swiper = new Swiper(infoCardSlider, {
    speed: 250,
    centeredSlides: true,
    centeredSlidesBounds: true,
    slidesPerView: 'auto',
    slidesOffsetAfter: 20,
    slidesOffsetBefore: 20,
    roundLengths: true,
    on: {
      slideChange: function (i) {
        infoCardTabs.forEach((element) => {
          element.classList.remove('text-primary', 'underline');
        });
        const myTab = document.querySelector(
          '[data-tab-num="' + i.activeIndex + '"]'
        );
        myTab.classList.add('text-primary', 'underline');
      },
    },
  });
}
