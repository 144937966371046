export default function openInNew() {
  const links = document.querySelectorAll(
    '[data-allow-new-window-icons] a[target="_blank"], .menu-item a[target="_blank"]'
  );

  links.forEach((link) => {
    link.insertAdjacentHTML(
      'beforeend',
      '<svg viewBox="0 0 10 10"><g><path fill="currentColor" d="M5.01 0v.83h-.143C3.573.83 2.278.83.984.827c-.092 0-.133.015-.132.12.003 2.684.003 5.367.004 8.05v.163h8.309V5.006H10l-.001 3.94c0 .218.01.444-.122.637-.175.258-.384.322-.712.311 0 0-5.55.034-8.31.034-.294 0-.855-.324-.855-.93 0 0 .026-6.165.027-7.92C.027.933.007.78.043.642.158.204.46.002.904.002 2.054-.002 3.204 0 4.353 0h.656Z" /><path fill="currentColor" d="M5.01 0v.83h-.143C3.573.83 2.278.83.984.827c-.092 0-.133.015-.132.12.003 2.684.003 5.367.004 8.05v.163h8.309V5.006H10l-.001 3.94c0 .218.01.444-.122.637-.175.258-.384.322-.712.311 0 0-5.55.034-8.31.034-.294 0-.855-.324-.855-.93 0 0 .026-6.165.027-7.92C.027.933.007.78.043.642.158.204.46.002.904.002 2.054-.002 3.204 0 4.353 0h.656Z" /><path fill="currentColor" d="M5.01 0v.83h-.143C3.573.83 2.278.83.984.827c-.092 0-.133.015-.132.12.003 2.684.003 5.367.004 8.05v.163h8.309V5.006H10l-.001 3.94c0 .218.01.444-.122.637-.175.258-.384.322-.712.311 0 0-5.55.034-8.31.034-.294 0-.855-.324-.855-.93 0 0 .026-6.165.027-7.92C.027.933.007.78.043.642.158.204.46.002.904.002 2.054-.002 3.204 0 4.353 0h.656Z" /><path fill="currentColor" d="M5.01 0v.83h-.143C3.573.83 2.278.83.984.827c-.092 0-.133.015-.132.12.003 2.684.003 5.367.004 8.05v.163h8.309V5.006H10l-.001 3.94c0 .218.01.444-.122.637-.175.258-.384.322-.712.311 0 0-5.55.034-8.31.034-.294 0-.855-.324-.855-.93 0 0 .026-6.165.027-7.92C.027.933.007.78.043.642.158.204.46.002.904.002 2.054-.002 3.204 0 4.353 0h.656Z" /><path fill="currentColor" d="m9.176 3.287-.005-1.612c0-.065-.002-.13-.004-.196l-.013-.05-.037.047c-.032.029-.065.057-.096.088l-3.323 3.31c-.03.03-.057.065-.086.098l-.6-.6 1.153-1.145 2.309-2.3c.024-.024.045-.052.068-.078l.02-.018L8.54.83 6.856.824c-.053 0-.105-.003-.158-.004l-.02-.024v-.79C6.723.004 6.76 0 6.8 0h2.747c.268 0 .448.162.45.425.005.956.002 1.912.002 2.883h-.8a.857.857 0 0 1-.023-.02Z" />  </g></svg>'
    );
  });
}
