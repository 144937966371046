import { animate, inView, stagger } from 'motion';

export default function init() {
  const leadershipGrid = document.querySelector('[data-el="leadership-grid"]');
  const leadershipGridItems = document.querySelectorAll(
    '[data-el="leadership-grid"] > div'
  );

  leadershipGridItems.forEach((el) => {
    el.style.opacity = 0;
  });
  inView(
    leadershipGrid,
    ({ target }) => {
      animate(
        leadershipGridItems,
        { opacity: [0, 1] },
        { duration: 0.5, delay: stagger(0.3) }
      );
    },
    { amount: 0.1 }
  );
}
