import Swiper from 'swiper';

export default function init() {
  const sliders = document.querySelectorAll('[data-gallery-slider]');

  sliders.forEach((slider) => {
    let mySize = 'wide';
    if (slider.dataset.slideSize) {
      mySize = slider.dataset.slideSize;
    }

    const slideSizes = {
      narrow: {
        640: {
          slidesPerView: 1.4,
        },
        768: {
          slidesPerView: 1.8,
        },
        1024: {
          slidesPerView: 1.8,
        },
        1280: {
          slidesPerView: 2.5,
        },
      },
      wide: {
        640: {
          slidesPerView: 1.4,
        },
        768: {
          slidesPerView: 1.5,
        },
        1024: {
          slidesPerView: 1.5,
        },
        1280: {
          slidesPerView: 1.5,
        },
      },
    };

    const gallerySlider = new Swiper(slider, {
      speed: 750,
      centeredSlides: true,
      centeredSlidesBounds: true,
      initialSlide: 1,
      spaceBetween: 0,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      slidesPerView: 1.2,
      breakpoints: {
        640: {
          slidesPerView: slideSizes[mySize][640].slidesPerView,
        },
        768: {
          slidesPerView: slideSizes[mySize][768].slidesPerView,
        },
        1024: {
          slidesPerView: slideSizes[mySize][1024].slidesPerView,
        },
        1280: {
          slidesPerView: slideSizes[mySize][1280].slidesPerView,
        },
      },
    });
  });
}
