import Headroom from 'headroom.js';

export default function stickyHeader() {
  var myElement = document.querySelector('body>header');
  var headroom = new Headroom(myElement, {
    offset: 75,
    tolerance: {
      up: 30,
      down: 0,
    },
  });
  headroom.init();
}
