import { animate, inView, stagger } from 'motion';
import SplitType from 'split-type';

export default function homepage() {
  const heroSubtitle = document.querySelectorAll('[data-el="hero-subtitle"]');

  heroSubtitle.forEach((el) => {
    // Subtitle animation
    const splitSubtitle = new SplitType(el, {
      types: 'chars',
      wordClass: 'overflow-hidden -my-16',
    });
    splitSubtitle.chars.forEach((char) => {
      char.style.opacity = 0;
    });
    inView(
      el,
      ({ target }) => {
        animate(
          splitSubtitle.chars,
          { opacity: [0, 1], y: ['30%', 0] },
          {
            duration: 0.6,
            delay: stagger(0.1),
          }
        );
      },
      { amount: 1 }
    );

    el.style.opacity = 1;
  });
}
