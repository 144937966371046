import { animate, spring, inView } from 'motion';

let active = false;
const animationBreakpoint = '896px'; // Breakpoint for switching to card track

export default function init() {
  const newsCards = document.querySelectorAll(
    '[data-el="latest-post-cards"] > div'
  );
  if (window.matchMedia(`(max-width: ${animationBreakpoint})`).matches) {
    active = false;
    return;
  } else if (!active) {
    const endRotations = {
      0: [2, 0],
      1: [3, 0],
      2: [-2, 0],
    };

    newsCards.forEach((el, i) => {
      const myEnd = endRotations[i];
      el.style.transform = `rotate(${myEnd[0]}deg)`;
      inView(
        el,
        ({ target }) => {
          animate(
            el,
            { rotate: [myEnd[0], myEnd[1]] },
            {
              easing: spring({
                stiffness: 50,
                damping: 2,
                restSpeed: 0.2,
                restDistance: 0.1,
              }),
            }
          );

          if (
            // Only run on desktop
            window.matchMedia(`(min-width: ${animationBreakpoint})`).matches
          ) {
            return (leaveInfo) => {
              // Needed to reset animation on leave, so it restarts when re-entering
            };
          }
        },
        { amount: 0.1 }
      );
    });

    active = true;
  }
}

window.addEventListener('resize', init);
