import { animate, stagger, spring } from 'motion';
import SplitType from 'split-type';

const titleVerticalOffset = {
  small: 10,
  medium: 15,
  large: 20,
};

export default function init() {
  const heroTitle = document.querySelector('[data-el="title-float"]');

  // Hero title animation
  const splitHero = new SplitType(heroTitle, {
    types: 'chars',
    charClass: 'overflow-hidden -my-16',
  });

  let verticalOffset = titleVerticalOffset.large;
  if (window.innerWidth < 640) {
    verticalOffset = titleVerticalOffset.small;
  } else if (window.innerWidth < 1024) {
    verticalOffset = titleVerticalOffset.medium;
  }

  animate(
    splitHero.chars,
    { y: [verticalOffset, 0] },
    {
      duration: 1.6,
      delay: stagger(0.05),
      easing: spring({ stiffness: 40, damping: 4 }),
    }
  );
  animate(
    splitHero.chars,
    { opacity: [0, 1] },
    {
      duration: 0.8,
      delay: stagger(0.05),
      at: '<',
    }
  );
  heroTitle.style.opacity = 1;
}
