import { animate, scroll, stagger, spring, timeline } from 'motion';
import SplitType from 'split-type';

const titleVerticalOffset = {
  small: 10,
  medium: 15,
  large: 20,
};

export default function HomepageHero() {
  const heroTitle = document.querySelector('[data-el="hero-title"]');
  const heroBottle = document.querySelector('[data-el="hero-bottle"]');
  const heroBG = document.querySelector('[data-el="hero-bg"]');
  const heroTagline = document.querySelector('[data-el="hero-tagline"]');
  const heroCTA = document.querySelector('[data-el="hero-cta"]');

  // Hero BG parallax
  scroll(animate(heroBG, { y: [0, 400] }));

  // Hero title animation
  const splitHero = new SplitType(heroTitle, {
    types: 'chars',
    // charClass: 'overflow-hidden -my-16',
  });

  let verticalOffset = titleVerticalOffset.large;
  if (window.innerWidth < 640) {
    verticalOffset = titleVerticalOffset.small;
  } else if (window.innerWidth < 1024) {
    verticalOffset = titleVerticalOffset.medium;
  }

  const animateTitle = [
    splitHero.chars,
    { y: [verticalOffset, 0] },
    {
      duration: 1.6,
      delay: stagger(0.05),
      easing: spring({ stiffness: 40, damping: 4 }),
    },
  ];
  const animateTitleOpacity = [
    splitHero.chars,
    { opacity: [0, 1] },
    {
      duration: 0.8,
      delay: stagger(0.05),
      at: '<',
    },
  ];

  const animateBottleOpacity = [
    heroBottle,
    { opacity: [0, 1] },
    { duration: 1.5, at: '<', delay: 0.5 },
  ];

  // Tagline animation
  const animateTagline = [
    heroTagline,
    { opacity: [0, 1] },
    { duration: 0.5, at: '0.5' },
  ];

  // CTA animation
  const animateCTA = [heroCTA, { opacity: [0, 1] }, { duration: 0.5 }];

  const sequence = [
    animateTitle,
    animateTitleOpacity,
    animateBottleOpacity,
    animateTagline,
    animateCTA,
  ];

  timeline(sequence, {
    delay: 0.5,
  });
  heroTitle.style.opacity = 1;
}
