import { animate, scroll, stagger, spring, timeline } from 'motion';
import SplitType from 'split-type';

const titleVerticalOffset = {
  small: 10,
  medium: 15,
  large: 20,
};

export default function init() {
  const heroTitle1 = document.querySelector('[data-el="hero-title-1"]');
  const heroTitle2 = document.querySelector('[data-el="hero-title-2"]');
  const heroTitle3 = document.querySelector('[data-el="hero-title-3"]');
  const heroBG = document.querySelector('[data-el="hero-bg"]');
  const heroTagline = document.querySelector('[data-el="hero-tagline"]');

  // Hero BG parallax
  scroll(animate(heroBG, { y: [0, 400] }));

  // Hero title animation
  const splitHero = new SplitType(heroTitle2, {
    types: 'chars',
    charClass: 'overflow-hidden -my-16',
  });

  let verticalOffset = titleVerticalOffset.large;
  if (window.innerWidth < 640) {
    verticalOffset = titleVerticalOffset.small;
  } else if (window.innerWidth < 1024) {
    verticalOffset = titleVerticalOffset.medium;
  }

  // Hero part 2 animation
  const animateTitle = [
    splitHero.chars,
    { y: [verticalOffset, 0] },
    {
      duration: 1.6,
      delay: stagger(0.05),
      easing: spring({ stiffness: 40, damping: 4 }),
    },
  ];
  const animateTitleOpacity = [
    splitHero.chars,
    { opacity: [0, 1] },
    {
      duration: 0.8,
      delay: stagger(0.05),
      at: '<',
    },
  ];

  // Hero part 1 animation
  const animateTitlePart1 = [
    heroTitle1,
    { opacity: [0, 1] },
    { duration: 0.5 },
  ];

  // Hero part 3 animation
  const animateTitlePart3 = [
    heroTitle3,
    { opacity: [0, 1] },
    { duration: 0.5 },
  ];

  timeline(
    [animateTitlePart1, animateTitle, animateTitleOpacity, animateTitlePart3],
    {
      delay: 0.5,
    }
  );
  heroTitle2.style.opacity = 1;
}
