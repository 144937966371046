import { animate, inView } from 'motion';

export default function init() {
  const animateIn = document.querySelectorAll('[data-animate-in]');

  animateIn.forEach((el) => {
    el.style.opacity = 0;

    inView(
      el,
      ({ target }) => {
        animate(target, { opacity: [0, 1], y: [20, 0] }, { duration: 0.5 });
      },
      { amount: 0.5 }
    );
  });
}
