// Components
import titleFloat from './components/title-float.js';
import animateTitle from './components/animate-title.js';
import parallax from './components/parallax.js';
import droplets from './components/droplets.js';
import introAnimations from './components/intro-animations.js';
import ticker from './components/ticker.js';
import dragCursor from '../../components/drag-cursor/drag-cursor.js';
import gallerySlider from './components/gallery-slider.js';
import newsCards from './components/news-cards.js';
import productInfoSlider from './components/product-info-slider.js';

// Page Specific
import homeHero from './pages/homepage-hero.js';
import productsHero from './pages/products-hero.js';
import productsImages from './pages/products.js';
import pageNaturalSource from './pages/natural-source.js';
import pagePeopleAndPlanet from './pages/people-and-planet.js';
import pageAbout from './pages/about.js';
import pageLeadership from './pages/leadership.js';

// Utilities
import stickyHeader from './utils/sticky-header.js';
import openInNew from './utils/open-in-new.js';
import disableDummyLinks from './utils/disable-dummy-links.js';
import accessibleMenu from './utils/accessible-menu.js';

// Alpine JS helper library
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';

document.addEventListener('DOMContentLoaded', function () {
  console.log('Welcome to Highland Spring');

  // Load Alpine JS
  // Used for burger menu, mobile footer, Natural Source and News page
  // These scripts are created within the template HTML pages
  window.Alpine = Alpine;
  Alpine.plugin(collapse);
  Alpine.plugin(focus);
  Alpine.start();

  // Global utils
  openInNew();
  stickyHeader();
  disableDummyLinks();
  accessibleMenu();

  // Components
  if (document.querySelector('[data-gallery-slider]')) gallerySlider();
  if (document.querySelector('[data-cursor="drag"]'))
    dragCursor(
      '/wp-content/themes/highland-spring/components/drag-cursor/drag-cursor.svg'
    );
  if (document.querySelector('[data-el="hero-subtitle"]')) animateTitle();
  if (document.querySelector('[data-el="latest-post-cards"]')) newsCards();
  if (document.querySelector('[data-el="title-float"]')) titleFloat();
  if (document.querySelector('[data-animate-in')) introAnimations();
  if (document.querySelector('[data-el="ticker"]')) ticker();
  if (document.querySelector('[data-el="droplet-container"]')) droplets();
  if (document.querySelector('[data-el="parallax"]')) parallax();
  if (document.querySelector('[data-el="product-info-slider"]'))
    productInfoSlider();

  // Page specific
  if (document.querySelector('.page-template-homepage')) homeHero();
  if (document.querySelector('.page-template-products')) productsHero();
  if (document.querySelector('.page-template-products')) productsImages();
  if (document.querySelector('.page-template-people-and-planet'))
    pagePeopleAndPlanet();
  if (document.querySelector('.page-template-leadership')) pageLeadership();
  if (document.querySelector('.page-template-about')) pageAbout();
  if (document.querySelector('.page-template-natural-source'))
    pageNaturalSource();
});
