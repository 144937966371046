import { animate, scroll, stagger, spring, timeline } from 'motion';
import SplitType from 'split-type';

const titleVerticalOffset = {
  small: 10,
  medium: 15,
  large: 20,
};

export default function init() {
  const heroContainer = document.querySelector('[data-el="hero"]');
  const heroTitle1 = document.querySelector('[data-el="hero-title-1"]');
  const heroTitle2 = document.querySelector('[data-el="hero-title-2"]');
  const heroBg = document.querySelector('[data-el="hero-bg"]');
  const heroBottle = document.querySelector('[data-el="hero-bottle"]');
  const cardContainers = document.querySelectorAll(
    '[data-el="card-container"]'
  );

  scroll(animate(heroBg, { y: [0, 1000] }, { target: heroContainer }));

  cardContainers.forEach((container) => {
    scroll(
      animate(
        container.querySelector('[data-el="source-card"]'),
        {
          y: [500, 0, 0, 0],
        },
        { offset: [0, 0.4, 0.6, 1] }
      ),

      { target: container, offset: ['start end', 'end start'] }
    );
    scroll(
      animate(
        container.querySelector('[data-el="line"]'),
        {
          height: [0, 1],
        },
        { offset: [0, 0.4, 0.6, 1] }
      ),
      {
        target: container,
        offset: ['start end', 'end end'],
      }
    );
  });

  scroll(
    animate(
      heroBottle,
      {
        transform: ['scale(1)', 'scale(.01)'],
      },
      { target: heroContainer }
    )
  );

  // Hero tagline animation
  const animateHeroBottle = [
    heroBottle,
    { opacity: [0, 1], y: [20, 0] },
    { duration: 0.75 },
  ];

  // Hero tagline animation
  const animateHeroTagline = [
    heroTitle1,
    { opacity: [0, 1], y: [20, 0] },
    { duration: 0.75 },
  ];

  // Hero title animation
  const splitHero = new SplitType(heroTitle2, {
    types: 'chars',
    charClass: 'overflow-hidden -my-16',
  });

  let verticalOffset = titleVerticalOffset.large;
  if (window.innerWidth < 640) {
    verticalOffset = titleVerticalOffset.small;
  } else if (window.innerWidth < 1024) {
    verticalOffset = titleVerticalOffset.medium;
  }

  // Hero part 2 animation
  const animateTitle = [
    splitHero.chars,
    { y: [verticalOffset, 0] },
    {
      duration: 1.6,
      delay: stagger(0.05),
      easing: spring({ stiffness: 40, damping: 4 }),
    },
  ];
  const animateTitleOpacity = [
    splitHero.chars,
    { opacity: [0, 1] },
    {
      duration: 0.8,
      delay: stagger(0.05),
      at: '<',
    },
  ];

  timeline(
    [animateHeroTagline, animateTitle, animateTitleOpacity, animateHeroBottle],
    {
      delay: 0.5,
    }
  );
  heroTitle2.style.opacity = 1;
}
