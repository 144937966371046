let settings = {
  mouseX: 0,
  mouseY: 0,
  xPos: 0,
  yPos: 0,
  speed: 1,
  offsetWidth: 0,
  offsetHeight: 0,
};
let customCursor = null;

/**
 * Initialise the custom cursor
 *
 * @param {string} cursorURL
 * @returns {void}
 *
 * @example
 * import dragCursor from './drag-cursor';
 * dragCursor('/wp-content/themes/highland-spring/components/drag-cursor/drag-cursor.svg');
 */
export default function init(cursorURL) {
  // Find all on page elements that require the custom cursor
  const interactiveElements = document.querySelectorAll('[data-cursor="drag"]');

  _loadCursor(cursorURL).then((cursor) => {
    if (cursor) {
      customCursor = cursor;
      interactiveElements.forEach((el) => {
        el.addEventListener('mouseenter', () => {
          _toggleCursor(true, el);
        });
        el.addEventListener('mouseleave', () => {
          _toggleCursor(false, el);
        });
        el.querySelectorAll('a').forEach((link) => {
          link.addEventListener('mouseenter', () => {
            _toggleCursor(false, el);
          });
        });
        el.querySelectorAll('a').forEach((link) => {
          link.addEventListener('mouseleave', () => {
            _toggleCursor(true, el);
          });
        });
      });
    } else {
      console.error('Custom cursor not loaded');
    }
  });
}

/**
 * Toggle the cursor on or off
 *
 * @param {boolean} show Toggle switch for cursor
 * @param {HTMLElement} el The element that the cursor is attached to
 * @returns {void}
 */
function _toggleCursor(show, el) {
  if (show) {
    const theme = el.dataset.bgTheme ? el.dataset.bgTheme : 'light';
    customCursor.dataset.theme = theme;
    el.addEventListener('pointermove', _determineMouseLocation);
    el.addEventListener('pointerdown', _mouseDownEvent);
    el.addEventListener('pointerup', _mouseUpEvent);
    customCursor.classList.add('active');
    document.body.dataset.hideCursor = true;
  } else {
    el.removeEventListener('pointermove', (e) => {
      _determineMouseLocation(e);
    });
    el.removeEventListener('pointerdown', _mouseDownEvent);
    el.removeEventListener('pointerup', _mouseUpEvent);
    customCursor.classList.remove('active');
    document.body.dataset.hideCursor = false;
  }
}

/**
 * Handle mouse down event
 *
 * @param {PointerEvent} event The pointer event
 * @returns {void}
 */
const _mouseDownEvent = function (event) {
  customCursor.classList.add('pressed');
};

/**
 * Handle mouse up event
 *
 * @param {PointerEvent} event The pointer event
 * @returns {void}
 */
const _mouseUpEvent = function (event) {
  customCursor.classList.remove('pressed');
};

/**
 * Determine the mouse location
 *
 * @param {PointerEvent} event The pointer event
 * @returns {void}
 */
const _determineMouseLocation = function (event) {
  settings.mouseX = event.pageX;
  settings.mouseY = event.pageY;

  requestAnimationFrame(_animateCustomCursor);
};

/**
 * Animate the custom cursor
 *
 * @returns {void}
 */
function _animateCustomCursor() {
  settings.xPos += (settings.mouseX - settings.xPos) / settings.speed;
  settings.yPos += (settings.mouseY - settings.yPos) / settings.speed;

  // Prevent cursor from going off screen and adding x-scrollbar to page
  if (settings.xPos >= document.body.clientWidth - settings.offsetWidth / 2) {
    settings.xPos = document.body.clientWidth - settings.offsetWidth / 2;
  }

  customCursor.style.transform = `translate(${
    settings.xPos - settings.offsetWidth / 2
  }px, ${settings.yPos - settings.offsetHeight / 2}px)`;
}

/**
 * Load the cursor SVG
 *
 * @param {string} cursorURL The URL of the cursor SVG
 * @returns {HTMLElement} The cursor element
 */

async function _loadCursor(cursorURL) {
  try {
    const response = await fetch(cursorURL, {
      headers: {
        method: 'GET',
        mode: 'same-origin',
        'Content-Type': 'image/svg+xml',
      },
    });
    if (response.ok) {
      const svgContent = await response.text();

      const cursor = document.createElement('div');
      cursor.classList.add('pointer');
      cursor.dataset.cursor = 'drag';
      document.body.appendChild(cursor);

      const cursorInner = document.createElement('div');
      cursorInner.classList.add('pointer-inner');
      cursor.appendChild(cursorInner);
      cursorInner.innerHTML = svgContent;

      settings.offsetWidth = cursor.offsetWidth;
      settings.offsetHeight = cursor.offsetHeight;

      return cursor;
    } else {
      throw new Error('SVG content not found');
    }
  } catch (error) {
    console.error('Error loading cursor');
    console.error(error);
    return false;
  }
}
